import request from '@/utils/request'

// 查询土壤风向温度传感器信息列表
export function soilTempAndSoilHumi() {
  return request({
    url: '/charts/soilTempAndSoilHumi',
    method: 'get',
  })
}

// 查询最新天气数据
export function getWeatherInfo() {
  return request({
    url: '/charts/weatherInfo?type=2',
    method: 'get'
  })
}
// 查询最近天气数据
export function getCurrentWeatherInfo() {
  return request({
    url: '/charts/currentWeatherInfo',
    method: 'get'
  })
}
// 新增土壤风向温度传感器信息
export function addData(data) {
  return request({
    url: '/system/data',
    method: 'post',
    data: data
  })
}

// 修改土壤风向温度传感器信息
export function updateData(data) {
  return request({
    url: '/system/data',
    method: 'put',
    data: data
  })
}

// 删除土壤风向温度传感器信息
export function delData(id) {
  return request({
    url: '/system/data/' + id,
    method: 'delete'
  })
}
// 报错信息
export function getwarnlog() {
  return request({
    url: '/charts/warnLog',
    method: 'get'
  })
}
// 已读
export function readLog(id) {
  return request({
    url: '/charts/readLog?id='+id,
    method: 'get'
  })
}
// 监控模块

export function getvideoOnlinelnfo() {
  return request({
    url: '/charts/getVideoOnlineInfo',
    method: 'get'
  })
}