import { render, staticRenderFns } from "./benRight2.vue?vue&type=template&id=238dc606&scoped=true"
import script from "./benRight2.vue?vue&type=script&lang=js"
export * from "./benRight2.vue?vue&type=script&lang=js"
import style0 from "./benRight2.vue?vue&type=style&index=0&id=238dc606&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "238dc606",
  null
  
)

export default component.exports