<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title">
              <span class="title-text">城西村高标准农田可视化平台</span>
              <dv-decoration-6
                class="dv-dec-6"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
              />
            </div>
            <dv-decoration-8
              class="dv-dec-8"
              :reverse="true"
              :color="decorationColor"
            />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex aside-width">
            <div class="react-left ml-4 react-l-s">
              <span class="react-left"></span>
              <span class="text"></span>
            </div>
            <div
              class="ml-3"
              @click="show(2)"
              :class="active == 2 ? 'bg-color-blue react-right' : 'react-left'"
            >
              <span class="text" :class="active == 2 ? 'fw-b' : ''"
                >数据分析2</span
              >
            </div>
          </div>
          <div class="d-flex aside-width">
            <div
              class="mr-3"
              :class="active == 3 ? 'bg-color-blue react-right' : 'react-left'"
              @click="show(3)"
            >
              <span class="text" :class="active == 3 ? 'fw-b' : ''"
                >数据分析3</span
              >
            </div>
            <div class="react-right mr-4 react-l-s">
              <span class="react-after"></span>
              <span class="text"
                >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
              >
            </div>
          </div>
        </div>

        <div class="body-box" v-if="active == 3">
          <!-- 第三行数据 -->
          <div class="content-box">
            <!-- <div>
              <dv-border-box-12>
                <centerLeft1 />
              </dv-border-box-12>
            </div> -->
            <div>
              <dv-border-box-12>
                <centerLeft2 />
              </dv-border-box-12>
            </div>
            <!-- 中间 -->
            <div>
              <center />
            </div>
            <!-- 中间 -->
            <!-- <div>
              <centerRight2 />
            </div> -->
            <div>
              <dv-border-box-13>
                <centerRight1 />
              </dv-border-box-13>
            </div>
          </div>

          <!-- 第四行数据 -->
          <div class="bottom-box">
            <dv-border-box-13>
              <bottomLeft />
            </dv-border-box-13>
            <dv-border-box-12>
              <bottomRight />
            </dv-border-box-12>
            <dv-border-box-13>
              <bottom-center></bottom-center>
            </dv-border-box-13>
          </div>
        </div>
        <div class="body-box2" v-if="active == 2">
          <div class="content-box-left">
            <div>
              <dv-border-box-13>
                <div
                  style="
                    width: 100%;
                    height: 430px;
                    padding: 10px;
                    margin-bottom: 10px;
                  "
                >
                  <h2
                    style="
                      text-align: center;
                      margin-top: 10px;
                      margin-bottom: 20px;
                      color: #ffff66;
                    "
                  >
                    农场介绍
                  </h2>
                  <div
                    style="
                      text-indent: 2em;
                      margin-bottom: 5px;
                      line-height: 22px;
                    "
                  >
                    城西村是桐乡市主城区的西大门，是链接城市与乡村的重要节点，为深入贯彻落实习近平总书记生态文明建设思想，实施梧桐街道城西村全域土地综合整治和生态修复工程，科学引导农户向新社区集聚，打破耕地碎片化，小田变大田，大田变千亩良田，让“城西粮仓”成为展现梧桐乡村振兴的最精彩板块。
                    梧桐街道城西村全域土地综合整治与生态修复工程，于2019年12月27日经省政府批准同意。该全域整治项目区总面积为9290亩，现有村民小组45个，农户1132户，总人口4575人。项目区内现状生态用地458亩，农业用地7190亩(其中耕地面积6192亩)，建设用地1644亩。
                  </div>
                  <div style="text-indent: 2em; line-height: 22px">
                    该项目规划千亩生态高效粮油示范区1010亩，建设高标准农田129亩，调入永久基本农田储备区381亩，实施宜耕后备资源开发新增耕地86亩，实施农村建设用地复垦295亩，搬迁农户520户(一期搬迁220户，二期搬迁300户)，退散进集企业12户。规划实施中路过桥港、李家石桥浜河道生态修复2.5公里，实施“城西田野慢道”1公里，修建精品道路钱翔线2公里，高家湾路1公里。通过完善钱林集镇小城镇环境综合整治、优美庭院建设等人居环境提升工程，完善新社区生态公共设施配套，打造生态宜居乡村邻里中心梧桐样板。
                  </div>
                </div>
              </dv-border-box-13>
            </div>
            <div>
              <dv-border-box-12>
                <el-carousel indicator-position="outside" height="430px">
                  <el-carousel-item
                    style="padding: 15px; text-align: center"
                    v-for="(item, index) in imgData"
                    :key="index"
                  >
                    <img
                      style="height: 100%; width: 100%"
                      :src="item.url"
                      alt=""
                    />
                  </el-carousel-item>
                </el-carousel>
              </dv-border-box-12>
            </div>
          </div>
          <div class="content-box-right">
            <div style="height: 42%; margin-bottom: 10px">
              <dv-border-box-12>
                <benRight />
              </dv-border-box-12>
            </div>
            <div style="height: 42%">
              <dv-border-box-12>
                <benRight2 />
              </dv-border-box-12>
            </div>
          </div>

          <div
            style="
              width: 100%;
              height: 90%;
              text-align: center;
              margin-bottom: 5px;
              position: absolute;
            "
          >
            <img src="@/assets/dt.png" alt="" style="height: 100%" />
          </div>
          <el-tooltip
            class="item"
            effect="dark"
            content="北蒋节灌泵站"
            placement="right"
          >
            <div class="module" style="top: 25%; left: 37%"></div>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="杨园村节灌泵站"
            placement="right"
          >
            <div class="module" style="top: 74%; left: 37%"></div>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="钟家浜节灌泵"
            placement="right"
          >
            <div class="module" style="top: 84.5%; left: 55%"></div>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" placement="right">
            <template v-slot:content>
              <img
                style="width: 150px; height: 200px"
                src="@/assets/气象站1.png"
                alt=""
              />
            </template>
            <div class="module" style="top: 71%; left: 46%"></div>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" placement="right">
            <template v-slot:content>
              <img
                style="width: 150px; height: 200px"
                src="@/assets/气象站2.png"
                alt=""
              />
            </template>
            <div class="module" style="top: 65%; left: 46%"></div>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from "../utils/index.js";
// import centerLeft1 from './centerLeft1'
import centerLeft2 from "./centerLeft2";
import centerRight1 from "./centerRight1";
// import centerRight2 from './centerRight2'
import center from "./center";
import bottomLeft from "./bottomLeft";
import bottomRight from "./bottomRight";
import benRight from "./benRight";
import benRight2 from "./benRight2";
import bottomCenter from "./bottomCenter.vue";

export default {
  mixins: [drawMixin],
  data() {
    return {
      timerId: null,
      active: 3,
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      decorationColor: ["#568aea", "#000000"],
      imgData: [
        {
          url: require("../assets/bg/1.jpg"),
        },
        {
          url: require("../assets/bg/2.jpg"),
        },
        {
          url: require("../assets/bg/3.jpg"),
        },
        {
          url: require("../assets/bg/4.jpg"),
        },
        {
          url: require("../assets/bg/5.jpg"),
        },
        {
          url: require("../assets/bg/6.jpg"),
        },
        {
          url: require("../assets/bg/7.jpg"),
        },
        {
          url: require("../assets/bg/8.jpg"),
        },
        {
          url: require("../assets/bg/9.jpg"),
        },
        {
          url: require("../assets/bg/91.jpg"),
        },
        {
          url: require("../assets/bg/92.jpg"),
        },
        {
          url: require("../assets/bg/93.jpg"),
        },
        {
          url: require("../assets/bg/94.jpg"),
        },
        {
          url: require("../assets/bg/95.jpg"),
        },
      ],
    };
  },
  components: {
    // centerLeft1,
    benRight,
    benRight2,
    centerLeft2,
    centerRight1,
    // centerRight2,
    center,
    bottomLeft,
    bottomRight,
    bottomCenter,
  },
  mounted() {
    this.timeFn();
    this.cancelLoading();
    this.startTimer();
  },
  beforeDestroy() {
    clearInterval(this.timing);
    this.stopTimer();
  },
  methods: {
    startTimer() {
      this.timerId = setInterval(() => {
        // 30秒后触发的操作
        if (this.active == 2) {
          this.show(3);
        } else if (this.active == 3) {
          this.show(2);
        }
      }, 30000); // 30000毫秒（30秒）
    },
    stopTimer() {
      if (this.timerId) {
        clearInterval(this.timerId);
        this.timerId = null;
      }
    },
    show(index) {
      this.active = index;
    },
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/index.scss";
</style>
