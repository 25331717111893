<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
import { soilTempAndSoilHumi } from "@/api/data";
export default {
  data() {
    return {
      cdata: {
        category: [
        ],
        lineData1: [
        ],
        lineData2: [
        ],
        lineData3: [
        ],
        lineData4: [
        ]
      }
    };
  },
  components: {
    Chart,
  },
  mounted() {
    this.getSoilTempAndSoilHumi();
  },
  methods: {
    generateArrayWithNineGroups() {
      const result = [];
      function generateRandomTwoDigitNumber() {
        return Math.floor(Math.random() * 10) + 10;
      }
      for (let j = 0; j < 7; j++) {
        result.push(generateRandomTwoDigitNumber());
      }

      return result;
    },
    // 根据自己的业务情况修改
    async getSoilTempAndSoilHumi() {
      const ret = await soilTempAndSoilHumi();
      console.log(ret);
      if (ret.data.dateTimeArray.length == 0) {
        let weekCategory = []
        for (let i = 0; i < 7; i++) {
          let date = new Date();
          weekCategory.unshift([date.getMonth() + 1, date.getDate() - i].join("/"));
        }
        this.cdata.category = weekCategory
        this.cdata.lineData1 = this.generateArrayWithNineGroups();
        this.cdata.lineData2 = this.generateArrayWithNineGroups();
        this.cdata.lineData3 = this.generateArrayWithNineGroups();
        this.cdata.lineData4 = this.generateArrayWithNineGroups();
      } else {
        this.cdata.category = ret.data.dateTimeArray;
        this.cdata.lineData1 = ret.data.soilTemp1Arry;
        this.cdata.lineData2 = ret.data.soilTemp2Arry;
        this.cdata.lineData3 = ret.data.soilTemp3Arry;
        this.cdata.lineData4 = ret.data.soilTemp4Arry;
      }


    },
  }
};
</script>

<style lang="scss" scoped></style>
