<template>
  <div id="center">
    <div class="down">
      <div class="bg-color-black item-right" style="position: relative">
        <div class="d-flex jc-center body-box">
          <dv-scroll-board
            @click="clickcut"
            class="dv-scr-board"
            :config="config"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: {},
      config: {
        header: ["阀门名称", "通信状态", "开启状态"],
        data: [
          ["1号阀门", "正常", "开启"],
          ["2号阀门", "正常", "开启"],
          ["3号阀门", "正常", "开启"],
          ["4号阀门", "正常", "开启"],
          ["5号阀门", "正常", "开启"],
          ["6号阀门", "正常", "开启"],
        ],
        rowNum: 5, //表格行数
        headerHeight: 15,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        // index: true,
        columnWidth: [],
        align: ["center", "center", "center"],
      },
      titleItem: [],
      titleItem2: [],
    };
  },
  components: {
    // CenterChart
  },

  methods: {
    // 切换表
    clickcut() {},
  },
};
</script>

<style lang="scss" scoped>
.tip-text {
  pointer-events: none; // 添加这行
  background: #000000;
  padding: 5px;
  font-size: 14px;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
}

.body-box {
  border-radius: 10px;
  overflow: hidden;

  .dv-scr-board {
    width: 96%;
    height: 400px;
  }
}

#center {
  display: flex;
  flex-direction: column;

  .down {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .item-right {
      border-radius: 6px;
      padding-top: 8px;
      margin-top: 8px;
      width: 100%;
      height: 400px;
    }

    .item {
      border-radius: 6px;
      padding-top: 8px;
      margin-top: 8px;
      height: 400px;

      .dv-dig-flop {
        width: 150px;
        height: 100px;
      }
    }
  }
}
</style>
