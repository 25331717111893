<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
import { getWeatherInfo } from '@/api/data'
export default {
  data() {
    return {
      drawTiming: null,
      cdata: {
        year: null,
        weekCategory: [],
        // radarData: [],
        // radarDataAvg: [],
        // maxData: 12000,
        weekMaxData: [],
        weekLineData: {}
      }
    }
  },
  components: {
    Chart,
  },
  mounted() {
    this.drawTimingFn();
  },
  beforeDestroy() {
    clearInterval(this.drawTiming);
  },
  methods: {
    generateArrayWithNineGroups() {
      const result = [];
      function generateRandomTwoDigitNumber() {
        return Math.floor(Math.random() * 90) + 10;
      }
      for (let j = 0; j < 7; j++) {
        result.push(generateRandomTwoDigitNumber());
      }

      return result;
    },
    async drawTimingFn() {
      this.setData();
      // this.drawTiming = setInterval(() => {
      //   this.setData();
      // }, 6000);
    },
    async setData() {
      // 清空轮询数据
      this.cdata.weekCategory = [];
      // this.cdata.weekMaxData = [];
      this.cdata.weekLineData = {};
      // this.cdata.radarData = [];
      // this.cdata.radarDataAvg = [];

      let dateBase = new Date();
      this.cdata.year = dateBase.getFullYear();
      // 周数据
      for (let i = 0; i < 7; i++) {
        // 日期
        let date = new Date();
        this.cdata.weekCategory.unshift([date.getMonth() + 1, date.getDate() - i].join("/"));
      }
      let res = await getWeatherInfo()
      console.log('res????',res.data);
      if (res.data.ah.length == 0) {
        res.data = {
          "at": this.generateArrayWithNineGroups(),
          "ps": this.generateArrayWithNineGroups(),
          "rf": this.generateArrayWithNineGroups(),
          "wsc": this.generateArrayWithNineGroups(),
          "ah": this.generateArrayWithNineGroups(),
          "x": this.generateArrayWithNineGroups(),
          "ws": this.generateArrayWithNineGroups(),
          "li": this.generateArrayWithNineGroups(),
          "wd": this.generateArrayWithNineGroups(),
          "hf": this.generateArrayWithNineGroups()
        }
      }
      console.log('res????',res);
      this.cdata.weekLineData = res.data
    }
  }
};
</script>

<style lang="scss" scoped></style>