<template>
  <div>
<!--    <div>土壤湿度/4层</div>-->
    <!-- 年度开工率 -->
    <Echart
      :options="options"
      id="bottomCenterChart"
      height="480px"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(255,255,255,0.1)",
            axisPointer: {
              type: "shadow",
              label: {
                show: true,
                backgroundColor: "#7B7DDC"
              }
            }
          },
          legend: {
            data: ["1", "2", "3","4"],
            textStyle: {
              color: "#7B7DDC"
            },
            top: "0%"
          },
          grid: {
            x: "8%",
            width: "88%",
            y: "4%"
          },
/*          xAxis: {
            data: newData.category,
            axisLine: {
              lineStyle: {
                color: "#B4B4B4"
              }
            },
            axisTick: {
              show: false
            }

          },*/
          xAxis: {
            type: 'category',
            data: newData.category
          },
          yAxis: [
            {
              splitLine: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#B4B4B4"
                }
              },

              axisLabel: {
                formatter: "{value} "
              }
            },
            {
              splitLine: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#B4B4B4"
                }
              },
              axisLabel: {
                formatter: "{value} "
              }
            }
          ],
          series: [
            {
              name: "1",
              data: newData.lineData1,
              type: 'line',
              smooth: true
            },
            {
              name: "2",
              data: newData.lineData2,
              type: 'line',
              smooth: true
            },
            {
              name: "3",
              data: newData.lineData3,
              type: 'line',
              smooth: true
            },
            {
              name: "4",
              data: newData.lineData4,
              type: 'line',
              smooth: true
            },
          ]
        }
      },
      immediate: true,
      deep: true
    },
  },
}
</script>
