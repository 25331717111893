<template>
  <div id="centerRight1" >
    <div class="bg-color-black" @mouseleave="leave" @mousemove="move"  style="position: relative;">
      <div class="d-flex jc-center body-box"  >
        <dv-scroll-board @mouseover="over" @click="clickRead" class="dv-scr-board" :config="config" />
      </div>
      <div v-if="tipShow" class="tip-text"   :style="{ top: tipText.offsetY, left: tipText.offsetX }">
        设备类型:{{ tipText.row[1] }} <br>
        详情:{{ tipText.row[2] }}

      </div>
    </div>
  </div>
</template>

<script>
import { getwarnlog,readLog } from '@/api/data'
export default {
  data() {
    return {
      options : {
       
      },
      config: {},
      tipText: {
        row:[],
        name: "",
        offsetX: "100px",
        offsetY: "200px",
      },
      tipShow: false, // 控制单元格显隐
    }
  },
  mounted() {
    this.datainit()
  },
  methods: {
    // 确认已读
    clickRead(data){
      
      readLog(data.row[0]).then(res=>{
        this.options={}
        console.log(res);
        this.datainit()
      })
    },
    // 鼠标移入
    over(dom) {
      this.tipShow=true
      this.tipText.row=dom.row
      if (!this.tipText.row) this.tipShow = false;
    },
    // 鼠标移动
    move(e) {
      const { left, top } = e.currentTarget.getBoundingClientRect();
      const xPosition = e.pageX - left;
      const yPosition = e.pageY - top;
      // 悬浮框位置可自行调整
      this.tipText.offsetX = xPosition + "px";
      this.tipText.offsetY = yPosition + "px";
    },
    // 鼠标离开
    leave() {
      //隐藏单元格
      this.tipShow = false;
    },

    async datainit() {
      this.options={}
      this.options={
        header: ['id','设备类型', '详情', '是否已读'],
        data: [],
        rowNum: 8, //表格行数
        headerHeight: 35,
        headerBGC: '#0f1325', //表头
        oddRowBGC: '#0f1325', //奇数行
        evenRowBGC: '#171c33', //偶数行
        // index: true,
        columnWidth: [],
        align: ['center']
      }
      const res = await getwarnlog()
      this.options.data = this.convertArray(res.data.data)
      this.config = this.options
    },
    convertArray(objArray) {
      // 检查 objArray 是否为空或者不是数组
      if (!Array.isArray(objArray) || objArray.length === 0) {
        return ['暂无数据','暂无数据','暂无数据','暂无数据'];
      }
      return objArray.map(item => [
        item.id,
        item.type,
        item.content,
        item.isRead === '0' ? "<span class='colorRed'>未读</span>" : "<span class='colorGrass'>已读</span>"
      ]);
    }
  },
}
</script>

<style lang="scss" scoped>
.tip-text {
  pointer-events: none; // 添加这行
  background: #000000;
  padding: 5px;
  font-size: 14px;
  // z-index: 200;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
}

$box-height: 410px;
$box-width: 300px;

#centerRight1 {
  padding: 16px;
  padding-top: 20px;
  height: $box-height;
  // width: $box-width;
  width: 100%;
  border-radius: 5px;

  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }

  .text {
    color: #c3cbde;
  }

  .body-box {
    border-radius: 10px;
    overflow: hidden;

    .dv-scr-board {
      // width: 270px;
      height: 340px;
      width: 100%;
    }
  }
}
</style>
