<template>
  <div id="center">
    <div class="up">
      <div class="bg-color-black item" v-for="item in titleItem" :key="item.id">
        <p style="font-size: 16px" class="ml-3 colorBlue fw-b fs-xl">
          {{ item.title }}
        </p>
        <div>
          <dv-digital-flop
            class="dv-dig-flop ml-1 mt-2 pl-3"
            :config="item.number"
          />
        </div>
      </div>
    </div>
    <div class="down">
      <div class="item-left">
        <div
          class="bg-color-black item"
          v-for="item in titleItem2"
          :key="item.id"
        >
          <p style="font-size: 16px" class="ml-3 colorBlue fw-b fs-xl">
            {{ item.title }}
          </p>
          <!-- 滚动表 -->
          <div>
            <dv-digital-flop
              class="dv-dig-flop ml-1 mt-2 pl-3"
              :config="item.number"
            />
          </div>
        </div>
      </div>

      <div
        class="bg-color-black item-right"
        @mouseleave="leave"
        @mousemove="move"
        style="position: relative"
      >
        <div class="d-flex jc-center body-box">
          <!-- @mouseover="over" @click="clickRead" -->
          <dv-scroll-board
            @mouseover="over"
            @click="clickcut"
            class="dv-scr-board"
            :config="config"
          />
          <div
            v-if="tipShow"
            class="tip-text"
            :style="{ top: tipText.offsetY, left: tipText.offsetX }"
          >
            通道名称:{{ tipText.row[0] }} <br />
            云台类型:{{ tipText.row[1] }}<br />
            在线状态:{{ this.extractTextFromHTML(tipText.row[2]) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import CenterChart from '@/components/echart/center/centerChartRate'
import { getCurrentWeatherInfo, getvideoOnlinelnfo } from "@/api/data";

export default {
  data() {
    return {
      tipText: {
        row: [],
        name: "",
        offsetX: "0px",
        offsetY: "0px",
      },
      tipShow: false, // 控制单元格显隐
      type: 1,
      options: {},
      config: {},
      titleItem: [],
      titleItem2: [],
    };
  },
  components: {
    // CenterChart
  },
  mounted() {
    this.getCurrentWeatherInfo();
    this.getvideoOnlinelnfo();
  },

  methods: {
    extractTextFromHTML(htmlString) {
      // 创建一个临时的 DOM 元素来解析 HTML 字符串
      let tempDiv = document.createElement("div");
      tempDiv.innerHTML = htmlString;

      // 提取 <span> 标签中的文本内容
      return tempDiv.querySelector("span").textContent;
    },
    // 切换表
    clickcut() {
      this.options = {};
      this.type = !this.type;
      this.getvideoOnlinelnfo();
    },
    // 鼠标移入
    over(dom) {
      this.tipShow = true;
      this.tipText.row = dom.row;
      if (!this.tipText.row) this.tipShow = false;
    },
    // 鼠标移动
    move(e) {
      const { left, top } = e.currentTarget.getBoundingClientRect();
      const xPosition = e.pageX - left;
      const yPosition = e.pageY - top;
      // 悬浮框位置可自行调整
      this.tipText.offsetX = xPosition + "px";
      this.tipText.offsetY = yPosition + "px";
    },
    // 鼠标离开
    leave() {
      //隐藏单元格
      console.log("离开了");
      this.tipShow = false;
    },
    convertArray(objArray) {
      // 检查 objArray 是否为空或者不是数组
      if (!Array.isArray(objArray) || objArray.length === 0) {
        return ["暂无数据", "暂无数据", "暂无数据"];
      }

      // 对非空数组进行转换
      return objArray.map((item) => [
        item.name,
        item.ptzTypeText,
        item.state === "0"
          ? "<span class='colorRed'>离线</span>"
          : "<span class='colorGrass'>在线</span>",
      ]);
    },
    async getCurrentWeatherInfo() {
      let options = [
        {
          title: "温度，单位：℃",
          number: {
            number: [],
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 26,
            },
          },
        },
        {
          title: "湿度，单位：%",
          number: {
            number: [],
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 26,
            },
          },
        },
        {
          title: "风向",
          number: {
            number: [],
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 26,
            },
          },
        },
        {
          title: "风力，单位：级",
          number: {
            number: [],
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 26,
            },
          },
        },
        {
          title: "风速，单位：m/s",
          number: {
            number: [],
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 26,
            },
          },
        },
        {
          title: "热通量",
          number: {
            number: [],
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 26,
            },
          },
        },
        {
          title: "光照，单位：lux",
          number: {
            number: [],
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 26,
            },
          },
        },
        {
          title: "气压，单位：KPa",
          number: {
            number: [],
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 26,
            },
          },
        },
        {
          title: "累计雨量，单位：mm",
          number: {
            number: [],
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 26,
            },
          },
        },
      ];
      let ret = await getCurrentWeatherInfo();
      let data = ret.data.data;
      // 温度
      options[0].number.number[0] = data.ambientTemperature;
      // 湿度，单位：%
      options[1].number.number[0] = data.ambientHumidity;
      // 风向
      options[2].number.number[0] = data.windDirection;
      // 风力，单位：级
      options[3].number.number[0] = data.windScale;
      // 风速，单位：m/s
      options[4].number.number[0] = data.windSpeed;
      // 热通量，单位：%
      options[5].number.number[0] = data.heatFlux;
      // 光照，单位：%
      options[6].number.number[0] = data.lightIntensity;
      // 气压，单位：%
      options[7].number.number[0] = data.pressure;
      // 累计雨量，单位：%
      options[8].number.number[0] = data.rainfall;
      this.titleItem = options;
    },
    async getvideoOnlinelnfo() {
      let options = [
        {
          title: "总通道数",
          number: {
            number: [],
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 26,
            },
          },
        },
        {
          title: "实际设备数",
          number: {
            number: [],
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 26,
            },
          },
        },
        {
          title: "实际在线设备数",
          number: {
            number: [],
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 26,
            },
          },
        },
        {
          title: "实际离线数据",
          number: {
            number: [],
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 26,
            },
          },
        },
      ];
      let res = await getvideoOnlinelnfo();
      let data = res.data.data;
      this.options = {
        header: ["通道名称", "云台类型", "在线状态"],
        data: [],
        rowNum: 3, //表格行数
        headerHeight: 20,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        // index: true,
        columnWidth: [],
        align: ["center"],
      };
      // 做切换
      if (this.type == 1) {
        this.options.data = this.convertArray(
          res.data.data.videoOnlineInfoList
        );
      } else {
        this.options.data = this.convertArray(res.data.data.videoOffInfoList);
      }
      this.config = this.options;
      // 总通道数
      options[0].number.number[0] = data.tdNum;
      // 实际设备数
      options[1].number.number[0] = data.realDeviceNum;
      // 实际在线设备数
      options[2].number.number[0] = data.reallineNum;
      // 实际离线数据
      options[3].number.number[0] = data.realOffNum;
      this.titleItem2 = options;
    },
  },
};
</script>

<style lang="scss" scoped>
.tip-text {
  pointer-events: none; // 添加这行
  background: #000000;
  padding: 5px;
  font-size: 14px;
  // z-index: 200;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
}

.body-box {
  border-radius: 10px;
  overflow: hidden;

  .dv-scr-board {
    // width: 270px;
    // height: 340px;
    width: 100%;
    height: 140px;
  }
}

#center {
  display: flex;
  flex-direction: column;

  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .item {
      border-radius: 6px;
      padding-top: 8px;
      margin-top: 8px;
      width: 32%;
      height: 70px;

      .dv-dig-flop {
        width: 150px;
        height: 30px;
      }
    }
  }

  .down {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .item-right {
      border-radius: 6px;
      padding-top: 8px;
      margin-top: 8px;
      width: 49%;
      height: 148px;
    }

    .item-left {
      width: 49%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }

    .item {
      border-radius: 6px;
      padding-top: 8px;
      margin-top: 8px;
      width: 48%;
      height: 70px;

      .dv-dig-flop {
        width: 150px;
        height: 30px;
      }
    }
  }
}
</style>
